import axios from "@/axios.js";

const moduleTaskAssignmentGeneric = {
  namespaced: true,
  state: {
    taskList: [],
    peopleInWorkspace: [],
    isLinkATaskEnabled: false,
    isTaskSelected: [],
  },
  getters: {
    getPeopleInWorkspace(state) {
      return state.peopleInWorkspace;
    },
    getTaskList(state) {
      return state.taskList;
    },
    isLinkATaskEnabled(state) {
      return state.isLinkATaskEnabled;
    },
    isTaskSelected(state) {
      return state.isTaskSelected;
    }
  },
  mutations: {
    setPeopleInWorkspace(state, people) {
      state.peopleInWorkspace = people;
    },
    setTaskList(state, tasks) {
      state.taskList = tasks;
    },
    pushNewTask(state, task) {
      state.taskList.push(task);
    },
    setIsLinkATaskEnabled(state, status) {
      state.isLinkATaskEnabled = status;
    },
    setIsTaskSelected(state, status) {
      state.isTaskSelected = status;
    }
  },
  actions: {
    async fetchPeopleInSelectWorkspace({ commit, rootGetters }, event) {
      const response = await axios.get(`/features/workspace/users`, {
        params: {
          user_id: rootGetters.getUser.id,
          id: event,
        },
      });
      commit("setPeopleInWorkspace", response.data.response);
    },
    async fetchPeopleInWorkspace({ commit, rootGetters }) {
      const response = await axios.get(`/features/workspace/users`, {
        params: {
          user_id: rootGetters.getUser.id,
          id: rootGetters.selectedWorkspace.id,
        },
      });
      commit("setPeopleInWorkspace", response.data.response);
    },
    async postNewTask({ commit, rootGetters }, task) {
      const response = await axios.post(`/features/tasks`, {
        workspace_id: task.workspace_id,
        assignee_id: task.assignee_id,
        reviewer_id: task.reviewer_id,
        website_id: task.website_id,
        brand_voice_id: task.brand_voice_id,
        additional_source_ids: task.additional_source,
        title: task.title,
        priority: task.priority_id,
        status: task.status_id,
        description: task.description_id,
        start_date: task.startDate,
        due_date: task.dueDate,
        task_attachments: task.attachments,
      });
      return response.data;
    },
    async fetchTasks({ commit, rootGetters }) {
      const response = await axios.get(`/features/tasks`);
      commit("setTaskList", response.data);
    },
  },
};

export default moduleTaskAssignmentGeneric;
